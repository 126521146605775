<template>
  <v-container
    id="patentlist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Лицензии, сертификаты, грамоты, дипломы, патенты
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Лицензии, сертификаты, грамоты, дипломы, патенты вашего центра, которые отображаются на титульной странице вашего центра</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  target="_blank"
                  @click="createPatent"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  :label="onlyArchivedLabel"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['id']"
            :sort-desc="[true]"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                  @click="singlePatent(item)"
                >
                  <td>{{ item.id }}</td>
                  <td
                    class="text-top"
                  >
                    <v-img
                      v-if="item.photo"
                      :src="imageLink(item.id)"
                      :lazy-src="imageLink(item.id)"
                      aspect-ratio="1.5"
                      width="100px"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.comment }}</td>
                  <td>{{ item.reply }}</td>
                  <td>{{ item.created }}</td>
                  <td class="text-right">
                    <v-btn
                      v-if="item.status"
                      color="error"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Переместить в архив"
                      small
                      :disabled="loading"
                      @click.stop="confirmArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!item.status"
                      color="info"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Вернуть из архива"
                      small
                      :disabled="loading"
                      @click.stop="confirmUnArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-restore
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Переместить отзыв в архив?
        </v-card-title>

        <v-card-text>
          После помещения в архив отзыв не будет отображаться на сайте.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="archive(selectedPatent)"
          >
            Переместить в архив
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUnArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Вернуть отзыв из архива?
        </v-card-title>

        <v-card-text>
          После возврата отзыва из архива он будет отображаться на сайте.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmUnArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="unarchive(selectedPatent)"
          >
            Вернуть из архива
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import patentApi from '../services/PatentApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    data () {
      return {
        search: CRMStorage.getItem('PTL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('PTL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('PTL_onlyArchived', false),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Изображение',
            value: 'photo',
          },
          {
            sortable: true,
            text: 'Название',
            value: 'name',
          },
          {
            sortable: false,
            text: '',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
          {
            sortable: true,
            text: 'Дата публикации',
            value: 'created',
          },
          {
            sortable: false,
            text: '',
            value: 'status',
          },
        ],
        items: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        confirmArchiveDialog: false,
        confirmUnArchiveDialog: false,
        selectedPatent: null,
      }
    },

    computed: {
      onlyArchivedLabel () {
        if (this.items.length < 1) {
          return 'Показать архивные'
        }
        return 'Показать архивные (' + this.items.filter((item) => item.status === 0).length + ')'
      },
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('PTL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('PTL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('PTL_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('PTL_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('PTL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('PTL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Лицензии, грамоты, патенты | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      this.fetchData()
    },
    methods: {
      singlePatent (item) {
        CRMStorage.setItem('PTL_scrollY', window.scrollY)
        this.$router.push('/patent/' + item.id)
      },
      createPatent () {
        this.$router.push('/patent/create')
        // return process.env.VUE_APP_OLD_URL + 'patent/create'
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
      fetchData () {
        this.loading = true
        patentApi
          .fetchPatents()
          .then(response => {
            this.items = response
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },

      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 'p/100/100/' + id
      },

      confirmArchive (patent) {
        this.selectedPatent = patent
        this.confirmArchiveDialog = true
      },

      confirmUnArchive (patent) {
        this.selectedPatent = patent
        this.confirmUnArchiveDialog = true
      },

      archive (patent) {
        this.confirmArchiveDialog = false
        patent.status = 0
        this.update(patent)
      },

      unarchive (patent) {
        this.confirmUnArchiveDialog = false
        patent.status = 1
        this.update(patent)
      },

      update (patent) {
        if (patent.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          patentApi
            .updatePatent(patent)
            .then(response => {
              patentApi.fetchPatents()
                .then(response => {
                  this.items = response
                  this.loading = false
                })
                .catch(error => {
                  this.handleError(error)
                })
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>
