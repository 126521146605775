import axios from 'axios'

export default {
  updatePatent (patent) {
    return axios.put('patents/' + patent.id, patent).then(response => {
      return response.data
    })
  },

  createPatent (patent) {
    return axios.post('patents', patent).then(response => {
      return response.data
    })
  },

  deletePatent (id) {
    return axios.delete('patents/' + id).then(response => {
      return response.data
    })
  },

  fetchPatent (id) {
    return axios.get('patents/' + id)
      .then(response => {
        return response.data
      })
  },

  fetchPatents () {
    return axios.get('patents').then(response => {
      return response.data
    })
  },
}
